<template>
  <div class="container">
    <header :class="!isUser ? '' : 'user_login'">
      <nav class="nav">
        <ul
          class="d-flex justify-content-between align-items-center flex-wrap textRight nav__list w-100"
        >
          <a
            :href="`${$store.state.common.publicPath}`"
            title="ExtraSlice"
            class="logo"
          >
            <img
              v-if="isSearchRequired"
              :src="`${$store.state.common.publicPath}img/logo.png`"
              alt="ExtraSlice"
            />
            <img
              v-if="!isSearchRequired"
              :src="`${$store.state.common.publicPath}img/logo.png`"
              alt="ExtraSlice"
            />
          </a>
          <li class="nav__item mlAuto md-mb0" :class="isUser ? 'mb10' : 'mb0'">
            <!-- <button
              class="btn btn--blue w100 contectUs"
              title="Book Now"
              @click="CHANGE_CONTECT_US_POP_UP(true)"
            >Contact us</button> -->
            <a
              :class="
                isSearchRequired
                  ? 'dropdown__toggle_white'
                  : 'dropdown__toggle_black'
              "
              class="nav__link contectUs"
              href="#"
              @click.prevent="CHANGE_CONTECT_US_POP_UP(true)"
            >
              Contact Us
            </a>
          </li>
          <li class="nav__item mb10 md-mb0" v-if="isAdmin">
            <a
              :href="urlPath"
              :class="
                isSearchRequired
                  ? 'dropdown__toggle_white'
                  : 'dropdown__toggle_black'
              "
              class="nav__link"
              title="AdminDashboard"
              @click="redirectToAdmin"
              >Admin Dashboard</a
            >
          </li>

          <li class="nav__item" v-if="!isUser">
            <a
              href="javascript:;"
              v-if="!hash"
              @click="openModal(false, true, false)"
              :class="
                showBlackFont
                  ? 'nav__link js-modalLogin signup black'
                  : 'nav__link js-modalLogin signup white'
              "
              title="Signup"
              >Signup</a
            >
          </li>

          <li class="nav__item position-relative" v-if="!isUser">
            <a
              href="javascript:;"
              v-if="!hash"
              :class="
                showBlackFont
                  ? 'nav__link js-modalLogin login black'
                  : 'nav__link js-modalLogin login white'
              "
              title="Login"
              @click="openModal(true, false)"
              >Login</a
            >
          </li>

          <li
            id="nav_dropdown"
            class="nav__item dropdown mb10 md-mb0 prolink"
            v-if="isAdmin || isUser"
            v-click-outside="closeDropdownMenu"
          >
            <button
              type="button"
              :class="
                isSearchRequired
                  ? 'dropdown__toggle_white'
                  : 'dropdown__toggle_black'
              "
              class="dropdown__toggle dropdown__toggle--hasArrow js-dropdownToggle"
              @click="toggleDropdownMenu"
            >
              {{ name }}
              <i class="fas fa-caret-down"></i>
            </button>
            <div class="c-avatar" style="margin-left: 8px">
              <img
                :src="
                  user.user.profile_image !== null
                    ? `${baseAPI}${user.user.profile_image}`
                    : `https://ui-avatars.com/api/?background=0D8ABC&color=fff&name=${name}&size=200`
                "
                class="c-avatar-img"
                v-if="isAdmin || isUser"
              />
            </div>
            <div class="dropdown__menu">
              <a
                href="javascript:;"
                @click="getContracts"
                class="dropdown__link"
                title="Contracts"
                >Contracts</a
              >
              <div class="dropdown__divider"></div>
              <a
                href="javascript:;"
                @click="userProfile"
                class="dropdown__link"
                title="Profile"
                style="cursor: pointer"
                >Profile</a
              >
              <div class="dropdown__divider"></div>
              <a
                href="javascript:;"
                @click="handleLogout"
                class="dropdown__link"
                title="Logout"
                style="cursor: pointer"
                >Logout</a
              >
            </div>
          </li>
          <div class="modal__overlay" v-if="isForgotPwd">
            <div class="modal__card modal__card--right forgot-modal">
              <button type="button" class="close" @click="closeModal('forgot')">
                <span aria-hidden="true">&times;</span>
              </button>
              <div class="fieldFloating">
                <input
                  type="text"
                  placeholder=" "
                  v-model="userEmail"
                  class="formControl"
                />
                <label class="fieldFloating__label required-field">Email</label>
              </div>
              <span
                v-if="$v.userEmail.$error && emailValid"
                class="error"
                style="color: red"
                >Email must be valid</span
              >
              <button
                class="buttonload btn btn--blue w100"
                style="pointer-events: none"
                v-if="isSubmitting"
              >
                Submitting ...
              </button>
              <button
                class="btn btn--blue w100"
                @click="onSave()"
                v-if="!isSubmitting"
              >
                Submit
              </button>
            </div>
          </div>
          <div class="modal__overlay" v-if="isLogin">
            <div class="modal__card modal__card--right forLogin">
              <button type="button" class="close" @click="closeModal('login')">
                <span aria-hidden="true">&times;</span>
              </button>
              <div class="fieldFloating">
                <input
                  type="text"
                  placeholder=" "
                  v-model="loginForm.username"
                  class="formControl"
                />
                <label class="fieldFloating__label required-field">Email</label>
              </div>
              <span
                v-if="$v.loginForm.username.$error && loginValidate"
                style="color: red; float: left; margin-left: 20px"
                class="error"
                >Email must be valid</span
              >
              <div class="fieldFloating">
                <input
                  type="password"
                  placeholder=" "
                  class="formControl"
                  v-model="loginForm.password"
                  @keyup.enter="handleLogin"
                />
                <label class="fieldFloating__label required-field"
                  >Password</label
                >
              </div>
              <span
                v-if="$v.loginForm.password.$error && loginValidate"
                style="color: red; float: left; margin-left: 20px"
                class="error"
                >Password required</span
              >
              <button
                class="buttonload btn btn--blue w100"
                style="pointer-events: none"
                v-if="isSubmittingLogin"
              >
                submitting ...
              </button>
              <button
                class="btn btn--blue w100"
                title="Login"
                v-if="!isSubmittingLogin"
                @click="handleLogin"
              >
                Login
              </button>
              <div
                class="textCenter d-block margin-term"
                data-toggle="tooltip"
                data-placement="bottom"
                title="we do not share or use your contact to any other organization of purpose"
              >
                <a href="#" class="terms-service" @click="whyLogin()"
                  >Why we need you to login</a
                >
              </div>
              <div class="or-container">
                <div class="line-separator"></div>
                <div class="or-label">or</div>
                <div class="line-separator"></div>
              </div>
              <div class="container">
                <div class="row text-center">
                  <div class="col-md-12">
                    <a
                      class="btn btn-lg btn-google btn-block text-uppercase btn-outline"
                      href="#"
                      @click="AuthProvider()"
                      ><img
                        src="https://img.icons8.com/color/16/000000/google-logo.png"
                      />
                      Login Using Google</a
                    >
                  </div>
                </div>
              </div>
              <br />
              <span class="textCenter d-block forgotlink">
                Forgot Password?
                <a href="#" class="click-here" @click="onForgotLink()"
                  >Click here</a
                >
              </span>
              <span class="textCenter d-block forgotlink">
                Don't have account ?
                <a href="#" class="" @click="onSignupLink()">Signup here</a>
              </span>
            </div>
          </div>

          <div class="modal__overlay" v-if="isSignUp">
            <div class="modal__card modal__card--right forSignup">
              <button type="button" class="close" @click="closeModal('signup')">
                <span aria-hidden="true">&times;</span>
              </button>
              <div class="fieldFloating mb20">
                <input
                  type="text"
                  placeholder=" "
                  v-model="signUpForm.name"
                  class="formControl"
                />
                <label class="fieldFloating__label required-field">Name</label>
                <span
                  v-if="$v.signUpForm.name.$error && regValidate"
                  class="error"
                  style="color: red; float: left; margin-left: 20px"
                  >Please enter your name</span
                >
              </div>
              <div class="fieldFloating mb20">
                <input
                  type="text"
                  placeholder=" "
                  v-model="signUpForm.email"
                  class="formControl"
                  @input="onInput()"
                />
                <label class="fieldFloating__label required-field">Email</label>
                <span
                  v-if="$v.signUpForm.email.$error && regValidate"
                  class="error"
                  style="color: red; float: left; margin-left: 20px"
                  >Email must be valid</span
                >
                <span
                  v-if="emailValidate"
                  style="color: red; float: left; margin-left: 20px"
                >
                  {{ emailValidate }}
                </span>
              </div>
              <div class="fieldFloating mb20">
                <input
                  type="password"
                  placeholder=" "
                  class="formControl"
                  v-model="signUpForm.password"
                />
                <label class="fieldFloating__label required-field"
                  >Password</label
                >
                <span
                  v-if="$v.signUpForm.password.$error && regValidate"
                  class="error"
                  style="color: red"
                  >Password must be at least 6 characters in length</span
                >
              </div>
              <!-- <div class="fieldFloating mb20">
                <input
                  type="file"
                  class="formControl"
                  @change="selectedFile($event)"
                />
                <label class="fieldFloating__label required-field"
                  >Profile Image</label
                >
              </div> -->
              <button
                class="buttonload btn btn--blue w100"
                style="pointer-events: none"
                v-if="isSubmittingReg"
              >
                submitting ...
              </button>
              <button
                class="btn btn--blue w100"
                title="SignUp"
                v-if="!isSubmittingReg"
                @click="handleSignUp"
              >
                Sign Up
              </button>
              <div
                class="textCenter d-block margin-term"
                data-toggle="tooltip"
                data-placement="bottom"
                title="we do not share or use your contact to any other organization of purpose"
              >
                <a href="#" class="terms-service" @click="termsService()"
                  >Terms of Service</a
                >
              </div>
              <div
                class="textCenter d-block margin-term"
                data-toggle="tooltip"
                data-placement="bottom"
              >
                <span class="forgotlink">Already have an account? </span>
                <a href="#" class="terms-service" @click="onSignInLink()"
                  >Login here</a
                >
              </div>
              <div class="or-container">
                <div class="line-separator"></div>
                <div class="or-label">or</div>
                <div class="line-separator"></div>
              </div>
              <div class="container">
                <div class="row text-center">
                  <div class="col-md-12">
                    <a
                      class="btn btn-lg btn-google btn-block text-uppercase btn-outline"
                      href="#"
                      @click="AuthProvider()"
                      ><img
                        src="https://img.icons8.com/color/16/000000/google-logo.png"
                      />
                      Signup Using Google</a
                    >
                  </div>
                </div>
              </div>
            </div>
            <br />
            <span class="textCenter d-block">
              Have an Account ?
              <a href="#" class="click-here" @click="onSignInLink()"
                >Login Here</a
              >
            </span>
            <span class="textCenter d-block">
              Forgot Password?
              <a href="#" class="click-here" @click="onForgotLink()"
                >Click here</a
              >
            </span>
            <div
              class="alert alert-danger signupError"
              v-if="isExplore"
              role="alert"
            >
              Please Signup to explore space!
            </div>
          </div>
        </ul>
      </nav>

      <ContectUsPopUp />
    </header>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { regUser, forgotPassword, getUserforUpdate } from "@/api/user";
import { required, minLength, email } from "vuelidate/lib/validators";
import ContectUsPopUp from "@/components/frontend/ContectUsPopUp.vue";

import Firebase from "firebase";
import swal from "sweetalert";

export default {
  name: "Header",
  components: {
    ContectUsPopUp,
  },
  data() {
    return {
      baseAPI: process.env.VUE_APP_BASE_API + process.env.VUE_APP_PROFILE_PATH,
      loginForm: {
        username: "", //admin@gmail.com(admin)
        password: "", //123456
      },
      signUpForm: {
        email: "",
        password: "",
        name: "",
        profile_image: "",
      },
      loading: false,
      isLogin: false,
      isForgotPwd: false,
      isSignUp: false,
      emailValidate: "",
      isExplore: false,
      userEmail: "",
      hash: "",
      regValidate: false,
      loginValidate: false,
      emailValid: false,
      isSubmitting: false,
      isSubmittingLogin: false,
      isSubmittingReg: false,
      registrationSuccessful: false,
    };
  },
  created() {
    this.hash = this.$route.params.hash;
    document.addEventListener("click", this.documentClick);
  },
  computed: {
    ...mapGetters(["role", "name", "user"]),
    urlPath() {
      return `${this.$store.state.common.publicPath}admin`;
    },
    isAdmin() {
      return this.role === "admin";
    },
    isUser() {
      return this.user && this.user.name;
    },
    isSearchRequired() {
      return this.$route.path == "/";
    },
    showBlackFont() {
      return this.$route.path !== "/" ? true : false;
    },
  },
  mounted() {
    this.$root.$on("getSignup", () => {
      this.isExplore = true;
      setTimeout(() => {
        this.openModal(false, true, true);
      }, 0);
    });
    this.$root.$on("getLogin", () => {
      this.isExplore = true;
      setTimeout(() => {
        this.openModal(true, false, true);
      }, 0);
    });
    let redirectTo = this.$route.query.redirectTo;
    if (redirectTo) {
      this.$notify({
        group: "notify",
        type: "error",
        text: "Please Login First",
      });
      this.openModal(true, false);
    }
  },
  updated() {
    if (!this.$router.params) {
      this.hash = false;
    }
  },
  beforeDestroy() {
    document.removeEventListener("click", this.documentClick);
  },
  methods: {
    ...mapMutations("popup", ["CHANGE_CONTECT_US_POP_UP"]),

    AuthProvider() {
      const provider = new Firebase.auth.GoogleAuthProvider();
      Firebase.auth()
        .signInWithPopup(provider)
        .then(async (result) => {
          /** @type {firebase.auth.OAuthCredential} */
          var credential = result.credential;
          var token = credential.accessToken;
          var user = result.user;
          var userData = {
            name: user.displayName,
            email: user.email,
            username: user.email,
            image: user.photoURL,
            accessToken: token,
            refreshToken: user.refreshToken,
            provider: "google",
          };
          console.log("user info", userData);
          let redirectTo = this.$router.history.current.query.redirectTo;
          this.loading = true;
          this.$store
            .dispatch("user/social_login", userData)
            .then(() => {
              this.$notify({
                group: "notify",
                type: "success",
                text: "Login successfully",
              });
              if (redirectTo) {
                this.$router.push({ path: redirectTo });
              }
              this.openModal();
              this.loading = false;
              this.isSubmittingLogin = false;
            })
            .catch((err) => {
              this.loading = false;
              console.log(err);
              // this.$notify({
              //     group: "notify",
              //     type: "error",
              //     text: err.response.data.error,
              // });
              this.isSubmittingLogin = false;
            });
          let idToken;
          await Firebase.auth()
            .currentUser.getIdToken(true)
            .then((res) => (idToken = res));
        })
        .catch((error) => {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
          // The email of the user's account used.
          var email = error.email;
          // The firebase.auth.AuthCredential type that was used.
          var credential = error.credential;
          // ...
        });
    },
    termsService() {
      swal(
        "we do not share or use your contact to any other organization of purpose"
      );
    },
    whyLogin() {
      return;
      swal(
        "we do not share or use your contact to any other organization of purpose"
      );
    },
    SocialLogin(provider, response) {
      this.$http
        .post("/sociallogin/" + provider, response)
        .then((response) => {
          console.log(response.data);
        })
        .catch((err) => {
          console.log({ err: err });
        });
    },
    hideModals() {
      console.log("Asd");
      this.isSignUp = false;
      this.isForgotPwd = false;
      this.isLogin = false;
    },
    closeModal(modal) {
      if (modal === "signup") {
        this.isSignUp = false;
      }
      if (modal === "login") {
        this.isLogin = false;
      }
      if (modal === "forgot") {
        this.isForgotPwd = false;
      }
    },
    documentClick(e) {
      var outside1 = true;
      var outside2 = true;
      var outside3 = true;

      var path = e.path || (e.composedPath && e.composedPath());

      // var outside4 = true
      if (path && path != undefined) {
        for (var i = 0; i < path.length - 1; ++i) {
          if (
            path[i].className != undefined &&
            typeof path[i].className.includes == "function"
          ) {
            if (
              path[i].className.includes("forgot-modal") ||
              path[i].className.includes("click-here")
            ) {
              outside1 = false;
              break;
            }
            if (
              path[i].className.includes("forLogin") ||
              path[i].className.includes("login")
            ) {
              outside2 = false;
              break;
            }
            if (
              path[i].className.includes("forSignup") ||
              path[i].className.includes("signup")
            ) {
              outside3 = false;
              break;
            }
            // if (
            //   e.path[i].className.includes("contectUs")
            // ) {
            //   outside4 = false;
            //   break;
            // }
          }
        }
      }
      if (outside1) {
        this.isForgotPwd = false;
        this.userEmail = "";
        this.emailValid = false;
        let body = document.body;
        body.classList.toggle("is-modalOpen");
      }
      if (outside2) {
        // this.isLogin = false;
        this.loginForm = {};
        this.loginValidate = false;
        let body = document.body;
        body.classList.toggle("is-modalOpen");
      }
      if (outside3) {
        // this.isSignUp = false;
        this.signUpForm = {};
        this.regValidate = false;
        let body = document.body;
        body.classList.toggle("is-modalOpen");
      }
    },

    clickOutside2() {
      var body = document.body;
      if (!this.isSignUp) this.isLogin = false;
      if (!this.isSignUp && !this.isForgotPwd)
        body.classList.remove("is-modalOpen");
    },
    clickOutside1() {
      var body = document.body;
      if (!this.isLogin) this.isSignUp = false;
      if (!this.isLogin && !this.isForgotPwd)
        body.classList.remove("is-modalOpen");
    },
    onForgotLink() {
      this.isForgotPwd = true;
      this.isLogin = false;
      this.isSignUp = false;
    },
    onSignInLink() {
      this.isSignUp = false;
      this.isLogin = true;
    },
    onSignupLink() {
      this.isSignUp = true;
      this.isLogin = false;
    },
    onSave() {
      this.emailValid = true;
      this.$v.userEmail.$touch();
      if (this.$v.userEmail.$error) {
        return;
      }
      this.emailValid = false;
      this.isSubmitting = true;
      forgotPassword({ email: this.userEmail })
        .then((res) => {
          this.$notify({
            group: "notify",
            type: "success",
            text: res.data.message,
          });
          this.isForgotPwd = false;
          this.isSubmitting = false;
        })
        .catch((err) => {
          this.$notify({
            group: "notify",
            type: "error",
            text: err.response.data.message,
          });
        });
    },
    userProfile() {
      this.$router.push("/profile");
      this.toggleDropdownMenu();
    },
    getContracts() {
      this.$router.push("/contracts");
      this.toggleDropdownMenu();
    },
    onInput() {
      this.emailValidate = "";
    },
    openModal(isLogin, isSignUp, isExplore = false, isForgotPwd) {
      this.isLogin = isLogin;
      this.isSignUp = isSignUp;
      this.isExplore = isExplore;
      this.isForgotPwd = isForgotPwd;

      var body = document.body;
      if (isSignUp) {
        setTimeout(function () {
          console.log(123);
          document
            .querySelector(".validate_number")
            .addEventListener("keypress", function (evt) {
              if (
                (evt.which != 8 && evt.which != 0 && evt.which < 48) ||
                evt.which > 57
              ) {
                evt.preventDefault();
              }
            });
        }, 0);
      }

      body.classList.toggle("is-modalOpen");
    },
    handleLogin() {
      this.loginValidate = true;
      let redirectTo = this.$router.history.current.query.redirectTo;
      console.log("redirectTo", redirectTo);
      this.$v.loginForm.$touch();
      if (this.$v.loginForm.$error) {
        return;
      }
      this.loginValidate = false;
      this.loading = true;
      this.isSubmittingLogin = true;
      this.$store
        .dispatch("user/login", this.loginForm)
        .then(() => {
          this.$notify({
            group: "notify",
            type: "success",
            text: "Login successfully",
          });
          if (redirectTo) {
            this.$router.push({ path: redirectTo });
          }
          this.openModal();
          this.loading = false;
          this.isSubmittingLogin = false;
        })
        .catch((err) => {
          this.loading = false;

          err.response.data.error &&
            this.$notify({
              group: "notify",
              type: "error",
              text: err.response.data.error,
            });
          if (err.response.data.data) {
            this.$notify({
              group: "notify",
              type: "error",
              text: err.response.data.data.email[0],
            });
          }
          this.isSubmittingLogin = false;
        });
    },
    selectedFile(event) {
      this.signUpForm.profile_image = event.target.files[0];
      console.log("dp", this.signUpForm.profile_image);
    },
    handleSignUp() {
      this.regValidate = true;
      this.$v.signUpForm.$touch();
      if (this.$v.signUpForm.$error) {
        return;
      }
      this.regValidate = false;
      this.loading = true;
      this.isSubmittingReg = true;
      console.log("----------------------", this.signUpForm);
      var formData = new FormData();
      formData.append("name", this.signUpForm.name);
      formData.append("email", this.signUpForm.email);
      formData.append("password", this.signUpForm.password);

      // if (this.signUpForm.profile_image) {
      // console.log(this.defaultImg)
      // formData.append("profile_image", this.defaultImg);
      // } else {
      formData.append(
        "profile_image",
        this.signUpForm.profile_image !== undefined
          ? this.signUpForm.profile_image
          : ""
      );
      // }
      console.log("formdata", formData);
      regUser(formData)
        .then((res) => {
          this.$notify({
            group: "notify",
            type: "success",
            text: "A verification email has been sent to you. Please verify your email to login.",
            duration: 10000,
          });
          this.openModal();
          this.loading = false;
          this.isSubmittingReg = false;
        })
        .catch((err) => {
          if (err.response.data.data.email) {
            this.emailValidate = err.response.data.data.email[0];
          }
          this.loading = false;
          this.isSubmittingReg = false;
        });
    },
    redirectToAdmin() {
      // this.$router.push({ path: "/admin" });
      // setTimeout(() => {
      //   this.$router.go(this.$router.currentRoute);
      // }, 500);
      // this.$router.push({ path: '/admin', reload: true })
      window.location.href = `${this.$store.state.common.publicPath}admin`;
    },
    async handleLogout() {
      await this.$store.dispatch("user/logout");
      this.$notify({
        group: "notify",
        type: "success",
        text: "logout successfully",
      });
      this.$router.push(`/`);
    },
    toggleDropdownMenu() {
      var element = document.getElementById("nav_dropdown");
      if (element.classList) {
        element.classList.toggle("is-dropdownOpen");
      } else {
        // For IE9
        var classes = element.className.split(" ");
        var i = classes.indexOf("is-dropdownOpen");

        if (i >= 0) classes.splice(i, 1);
        else classes.push("is-dropdownOpen");
        element.className = classes.join(" ");
      }
    },
    closeDropdownMenu() {
      var element = document.getElementById("nav_dropdown");

      if (element.classList) {
        element.classList.remove("is-dropdownOpen");
      } else {
        // For IE9
        var classes = element.className.split(" ");
        var i = classes.indexOf("is-dropdownOpen");

        if (i >= 0) classes.splice(i, 1);
      }
    },
  },
  validations: {
    signUpForm: {
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(6),
      },
      name: {
        required,
      },
    },
    loginForm: {
      username: {
        required,
      },
      password: {
        required,
      },
    },
    userEmail: {
      required,
      email,
    },
  },
};
</script>

<style>
.dropdown__toggle_white {
  color: #ffffff !important;
}
.dropdown__toggle_black {
  color: #000000 !important;
}
.signupError {
  margin-top: 20px;
  border-radius: 50px;
  text-align: center;
  font-weight: bold;
  color: #ec5d70;
}
</style>

<style scoped>
.error {
  display: block;
  font-size: 13px;
  color: tomato;
  margin-top: 0.3rem;
  margin-bottom: 1rem;
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  animation-duration: 0.4s;
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.black {
  color: black;
}
.white {
  color: white;
}

.text-center {
  text-align: center;
}

.margin-term {
  margin-top: 0.5em;
}
</style>

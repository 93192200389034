<template>
  <div v-if="show">
    <div class="modal__overlay">
      <div class="modal__card modal__card--right contectUs">
        <div class="row">
          <div class="col align-self-end" style="text-align: right">
            <button
              type="button"
              class="close"
              @click.prevent="CHANGE_CONTECT_US_POP_UP(false)"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
        <div class="fieldFloating">
          <input
            type="text"
            placeholder=" "
            v-model="name"
            class="formControl"
          />
          <label class="fieldFloating__label required-field">Name</label>
          <span
            v-if="$v.name.$error && !$v.name.$dirty"
            class="error"
            style="color: red"
            >Name is required</span
          >
        </div>
        <div class="fieldFloating">
          <input
            type="text"
            placeholder=" "
            v-model="email"
            class="formControl"
          />
          <label class="fieldFloating__label required-field">Email</label>
          <span
            v-if="$v.email.$error && !$v.email.$dirty"
            class="error"
            style="color: red"
            >Email must be required and valid</span
          >
        </div>
        <div class="fieldFloating">
          <!-- <CInput
            v-model="phoneNumber"
            type="text"
            v-mask="'(###) ###-####'"
            placeholder="Enter Mobile number"
            class="formControl"
          /> -->
          <!-- <span v-if="$v.phoneNumber.$error" class="text-danger"
        >Phone format should be : (000) 000-0000</span
      > -->
          <input
            type="tel"
            placeholder=" "
            v-mask="'(###) ###-####'"
            v-model="phoneNumber"
            class="formControl"
          />
          <!-- <input type="tel" id="phone" name="phone" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"> -->
          <label class="fieldFloating__label required-field"
            >Phone number</label
          >
        </div>
        <div class="fieldFloating">
          <select class="formControl" v-model="subject">
            <option value=""></option>
            <option value="Contact sales">Contact sales</option>
            <option value="Request a tour">Request a tour</option>
            <option value="List a property">List a property</option>
            <option value="Support requests">Support requests</option>
            <option value="Landlord">Landlord</option>
            <option value="Broker">Broker</option>
            <option value="Other inquiries">Other inquiries</option>
          </select>
          <label class="fieldFloating__label required-field">Subject</label>
          <span
            v-if="$v.subject.$error && !$v.subject.$dirty"
            class="error"
            style="color: red"
            >Subject is required</span
          >
        </div>
        <div class="fieldFloating">
          <textarea v-model="description" maxlength="5000" class="formControl">
          </textarea>
          <label class="fieldFloating__label required-field">Description</label>
          <span
            v-if="$v.description.$error && !$v.description.$dirty"
            class="error"
            style="color: red"
            >Description is required</span
          >
        </div>

        <button
          class="buttonload btn btn--blue w100"
          style="pointer-events: none"
          v-if="isSubmittingReg"
        >
          submitting ...
        </button>
        <button
          class="btn btn--blue w100"
          title="Submit"
          v-if="!isSubmittingReg"
          @click.once="submit"
        >
          Submit
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { required, email } from "vuelidate/lib/validators";
import { storeContactus } from "@/api/contectus";
import { mapGetters, mapState, mapMutations } from "vuex";
import { mask } from "vue-the-mask";
export default {
  name: "ContectUsPopUp",
  directives: { mask },
  data: () => ({
    // show: false,
    name: "",
    email: "",
    phoneNumber: "",
    subject: "",
    description: "",
    isSubmittingReg: false,
  }),
  validations: {
    name: {
      required,
    },
    email: {
      required,
      email,
    },
    subject: {
      required,
    },
    description: {
      required,
    },
  },
  computed: {
    ...mapGetters(["user"]),
    ...mapState("popup", { show: (state) => state.contectUsPopUp }),
  },
  created() {
    // this.clearDetails();
    document.addEventListener("click", this.documentClick);
  },
  beforeDestroy() {
    //  this.clearDetails();
    document.removeEventListener("click", this.documentClick);
  },
  mounted() {
    this.name = this.user.user ? this.user.user.name : "";
    this.email = this.user.user ? this.user.user.email : "";
    this.phoneNumber = this.user.user ? this.user.user.mobile : "";
  },
  watch: {
    user: {
      handler(val) {
        this.clearDetails();
        this.name = this.user.user ? this.user.user.name : "";
        this.email = this.user.user ? this.user.user.email : "";
        this.phoneNumber = this.user.user ? this.user.user.mobile : "";
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations("popup", ["CHANGE_CONTECT_US_POP_UP"]),
    documentClick(e) {
      let outside = true;
      var path = e.path || (e.composedPath && e.composedPath());
      if (path) {
        for (var i = 0; i < path.length - 1; ++i) {
          if (
            path[i].className != undefined &&
            typeof path[i].className.includes == "function"
          ) {
            if (path[i].className.includes("contectUs")) {
              outside = false;
              break;
            }
          }
        }
      }
      if (outside) {
        this.CHANGE_CONTECT_US_POP_UP(false);
      }
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$error) {
        return;
      }
      
      storeContactus({
        email: this.email,
        phone_number: this.phoneNumber,
        subject: this.subject,
        description: this.description,
        name: this.name,
      })
        .then((res) => {
          this.$notify({
            group: "notify",
            type: "success",
            text: "Contact request send successfully. Someone from ExtraSlice would be contacting you via email or phone",
          });
          this.subject = "";
          this.description = "";
          // this.clearDetails();
          this.CHANGE_CONTECT_US_POP_UP(false);
        })
        .catch((error) => {
          this.$notify({
            group: "notify",
            type: "error",
            text: error.response.data.data.message,
          });
          this.subject = "";
          this.description = "";
          // this.clearDetails();
        });
    },
    clearDetails() {
      this.name = "";
      this.email = "";
      this.phoneNumber = "";
      this.subject = "";
      this.description = "";
    },
  },
};
</script>

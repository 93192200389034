<template>
  <div class="search">
    <div class="container">
      <h1 class="search__heading">
        Making office space truly agile for tech companies
      </h1>
      <p class="search__subText">
        See how we provide the all-inclusive office space solution so that you
        can focus on growing your company.
      </p>
      <div class="search__fields">
        <div class="row no-gutters">
          <div class="col-md-4 border-left-none">
            <div class="search__field fieldFloating">
              <autocomplete
                @getLocation="getLocation"
                :isFromLanding="true"
                :isReadonly="false"
                fromFront="1"
              />
            </div>
          </div>
          <div class="col-md-4" v-click-outside="outsideClick">
            <div class="">
              <div class="head-count">
                <div class="search__field fieldFloating">
                  <input
                    type="text"
                    max="500"
                    min="1"
                    @input="onChange()"
                    placeholder=" "
                    :value="headCount"
                    class="formControl validate_number"
                    @click="isSlider = true"
                  />

                  <label class="fieldFloating__label">Head counts</label>
                </div>
              </div>
              <!-- <VueSimpleRangeSlider
                                v-if="isSlider"
                                style="width: 300px"
                                :min="0"
                                :max="500"
                                v-model="range"
                            /> -->
              <!-- <div class="col-md-6" style="padding: 0">
                                <div class="search__field fieldFloating">
                                    <input
                                        type="number"
                                        max="500"
                                        min="1"
                                        @input="onChange()"
                                        placeholder=" "
                                        v-model="capacity[1]"
                                        class="formControl validate_number"
                                        @click="isMaxSlider = true"
                                    />
                                    <label class="fieldFloating__label"
                                        >Max Head counts</label
                                    >
                                </div>
                            </div> -->
            </div>
            <div class="search__field fieldFloating minmaxslider">
              <vue-slider
                v-if="isSlider"
                ref="slider"
                v-model="headCount"
                v-bind="options"
                :max="max"
                :min="min"
                class="formControl"
              ></vue-slider>
              <!-- <vue-slider
                                v-if="isMaxSlider"
                                ref="slider"
                                v-model="capacity[1]"
                                v-bind="options"
                                :max="max"
                                :min="min"
                                class="formControl"
                            ></vue-slider> -->
            </div>
          </div>
          <div class="col-md-4">
            <div class="search__field fieldFloating">
              <div class="head-count d-flex">
                <datepicker
                  ref="picker"
                  @focusout.native="close"
                  class="formControl"
                  v-model="start_date"
                  @focusin.native="getStartDate"
                  :disabledDates="disabledDates"
                >
                </datepicker>
                <button
                  v-if="start_date"
                  class="date-reset"
                  @click="start_date = null"
                >
                  X
                </button>
              </div>
              <label
                :class="
                  start_date
                    ? 'fieldFloating__label date_selected'
                    : 'fieldFloating__label date_open'
                "
                >Start Date</label
              >
            </div>
          </div>
        </div>
      </div>
      <div class="search__bottom">
        <div class="row flex-column">
          <div class="col-md-4 mlAuto mrAuto">
            <button
              class="btn btn--blue w100 signup1"
              type="button"
              title="Get Started"
              @click="exploreSpace"
            >
              Get Started
            </button>
          </div>

          <div class="col-md-8 mlAuto mrAuto">
            <div class="search__result">
              <span class="colorWhite">25</span> spaces starting at
              <span class="colorWhite">$310</span> monthly per person
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import autocomplete from "./AutoComplete";
import Datepicker from "vuejs-datepicker";
import VueSlider from "vue-slider-component";
import { getToken } from "@/utils/auth";

import VueSimpleRangeSlider from "vue-simple-range-slider";
import "vue-simple-range-slider/dist/vueSimpleRangeSlider.css";

export default {
  name: "Dashboard",
  components: {
    Datepicker,
    VueSlider,
    autocomplete,
    VueSimpleRangeSlider,
  },
  beforeRouteUpdate(to, from, next) {
    console.log("landing to", to);
    console.log("landing from", from);
  },
  data() {
    return {
      headCount: null,
      range: [0, 0],
      capacity: [null, null],
      min: 0,
      max: 500,
      start_date: null,
      location: null,
      isSlider: false,
      isMaxSlider: false,
      options: {
        dotSize: 14,
        width: "auto",
        height: 4,
        contained: false,
        direction: "ltr",
        data: null,
        min: 0,
        max: 100,
        interval: 1,
        disabled: false,
        clickable: true,
        duration: 0.5,
        adsorb: false,
        lazy: false,
        tooltip: "focus",
        tooltipPlacement: "top",
        tooltipFormatter: void 0,
        useKeyboard: false,
        enableCross: true,
        fixed: false,
        minRange: void 0,
        maxRange: void 0,
        order: true,
        marks: false,
        dotOptions: void 0,
        process: true,
        dotStyle: void 0,
        railStyle: void 0,
        processStyle: void 0,
        tooltipStyle: void 0,
        stepStyle: void 0,
        stepActiveStyle: void 0,
        labelStyle: void 0,
        labelActiveStyle: void 0,
      },
      disabledDates: {
        to: new Date(Date.now() - 8640000),
      },
      weekdays: ["M", "T", "W", "T", "F", "S", "S"],
    };
  },
  mounted() {
    var testarray = document.getElementsByClassName("vdp-datepicker");
    if (this.start_date) {
      testarray[0].classList.add("is-open-date");
    } else {
      testarray[0].classList.remove("is-open-date");
    }

    document
      .querySelector(".validate_number")
      .addEventListener("keypress", function (evt) {
        if (
          (evt.which != 8 && evt.which != 0 && evt.which < 48) ||
          evt.which > 57
        ) {
          evt.preventDefault();
        }
      });
  },
  methods: {
    onChange() {
      if (this.range[1] > 500) {
        alert("you can select upto 500");
        this.range[1] = 500;
      }
    },
    outsideClick() {
      this.isSlider = false;
      this.isMaxSlider = false;
    },
    removeClasss() {
      var body = document.body;
      body.classList.remove("is-modalOpen");
    },
    exploreSpace() {
      // if (!getToken()) {
      //   // this.location = "";
      //   // this.capacity = "";
      //   // this.start_date = "";
      //   this.$root.$emit("getSignup", true);
      //   // alert("please login first");
      // } else {
      if (
        !this.location == "" ||
        !this.capacity == "" ||
        !this.start_date == ""
      ) {
        let query = {};
        if (this.location) {
          query.location = this.location;
        }
        let startDate = null;
        if (this.headCount) {
          console.log(this.range);
          query.min_capacity = this.range[0];
          query.max_capacity = this.range[1];
          query.headCount = this.headCount;
        }

        if (this.start_date) {
          let year = this.start_date.getFullYear();
          let date = this.start_date.getDate();
          let month = this.start_date.getMonth() + 1;

          startDate = year + "-" + month + "-" + date;
          query.start_date = startDate;
          console.log("here is query start date", query.start_date);
        }
        console.log("query", query);
        this.$router.push({
          path: "list",
          query: query,
        });
      } else {
        alert("please fill up data");
      }
      // }
    },
    getLocation(location) {
      this.location = location;
    },
    getStartDate() {
      var testarray = document.getElementsByClassName("vdp-datepicker");
      if (!this.$refs.picker.isOpen) {
        testarray[0].classList.add("is-open-date");
      } else {
        testarray[0].classList.remove("is-open-date");
      }
    },
    close() {
      var testarray = document.getElementsByClassName("vdp-datepicker");
      if (!this.$refs.picker.isOpen && !this.start_date) {
        testarray[0].classList.remove("is-open-date");
      } else {
        testarray[0].classList.add("is-open-date");
      }
    },
  },
  watch: {
    headCount: {
      immediate: true,
      handler(val) {
        let count = parseInt(val, 10);
        this.range[0] =
          count - (count * 20) / 100 - 5 < 0
            ? 0
            : parseInt(count - (count * 20) / 100 - 5, 10);
        this.range[1] = parseInt(count + (count * 20) / 100 + 5, 10);
        console.log(this.range);
      },
    },
  },
};
</script>
<style scoped lang="scss">
.no-border-radius {
  border-radius: 0;
}
.minmaxslider {
  width: 100%;
  position: absolute;
  top: 102%;
}
.simple-range-slider {
  width: 98% !important;
  background-color: white;
  padding: 0;
}
.search_fields {
  .no-gutters:first-child {
    border-left: none !important;
  }
}
.border-left-none {
  border-left: none !important;
}

@media (min-width: 768px) {
  .head-count {
    padding: 0;
  }
}
</style>
